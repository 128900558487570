html,
body,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
}

div {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

html {
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  -webkit-text-size-adjust: 100%;
}

body {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  font-family: "Nunito Sans", sans-serif;
  color: #181a1c;
}

body.odd {
  background-color: #000000;
}

/* body .odd {
  color: #ffffff;
} */

ol,
ul {
  padding-left: 20px;
  text-align: left;
}

ol {
  list-style: decimal;
}

ul {
  list-style: disc;
}

ul ul {
  list-style: circle;
}

pre {
  display: block;
  margin: 1rem 0;
  color: #000;
}

code {
  display: inline;
  margin: 1rem 0;
  color: #000;
}

blockquote,
q {
  display: block;
  margin: 2rem 0;
  quotes: none;
  quotes: none;
  font-style: italic;
  padding: 1rem 1.5rem;
  border-left: 5px solid rgba(5, 130, 131, 0.15);
}

.text-center blockquote:not(.text-left),
.text-center q:not(.text-left) {
  border-top: 5px solid rgba(5, 130, 131, 0.15);
  border-bottom: 5px solid rgba(5, 130, 131, 0.15);
  border-left: none;
}

.text-right blockquote,
.text-right q {
  border-right: 5px solid rgba(5, 130, 131, 0.15);
  border-left: none;
}

/* .odd blockquote,
q {
  border-color: #000;
} */

/* blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

blockquote p,
q p {
  margin: 0;
} */

/* .blockquote-footer {
  margin-top: 10px;
  font-size: 14px;
} */

figure {
  margin-bottom: 1.5rem;
}

figcaption {
  margin-bottom: 1.5rem;
  font-style: italic;
  padding: 1rem 1.5rem;
  border-left: 5px solid rgba(5, 130, 131, 0.15);
}

.odd figcaption {
  border-left: 5px solid #191919;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

::selection {
  background: rgba(166, 175, 189, 0.3);
}

::-moz-selection {
  background: rgba(166, 175, 189, 0.3);
}

*,
*:after,
*:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

a:not(.btn),
a:link:not(.btn),
a:visited:not(.btn),
a:hover:not(.btn),
a:active:not(.btn) {
  font-size: 1em;
  font-weight: 400;
  line-height: 1.5;
  /* color: #000; */
  text-decoration: none;
}

a:not([href]),
a:not([href]):hover,
a:not([href]) i,
a:not([href]) i:hover {
  color: #f9f9f9;
}

p {
  font-size: 1rem;
  font-weight: 100;
  line-height: 1.75;
}

p:first-child {
  margin-top: 0;
}

p:last-child {
  margin-bottom: 0;
}

h1,
.slide-content .title {
  margin-bottom: 1.5rem;
  font-size: 4rem;
  font-weight: 600;
  line-height: 1.2;
  font-family: "Nunito Sans", sans-serif;
  color: #000000;
  -ms-word-wrap: break-word;
  word-wrap: break-word;
}

.odd h1,
.odd .slide-content .title {
  color: #ffffff;
}

h1 em,
.slide-content .title em {
  font-weight: 300;
  display: block;
  font-size: 3.5rem;
}

h1 .featured {
  position: relative;
  display: inline-block;
  padding: 5px 15px;
  color: #000;
}

.odd h1 .featured {
  color: #ffffff;
}

h1 .featured:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  background-color: #000;
  opacity: 0.15;
}

h1 .featured span {
  position: relative;
}

h1 .featured.bottom {
  padding: 0;
}

h1 .featured.bottom:before {
  top: 50%;
  left: 3px;
  width: 100%;
  height: 42%;
  opacity: 0.15;
}

.odd h1 .featured.bottom:before {
  opacity: 0;
}

h1 .pre-title {
  position: relative;
  bottom: 10px;
  font-family: "Nunito Sans", sans-serif;
  font-size: 1rem;
  font-weight: 600;
}

.pre-title {
  width: fit-content;
  margin: auto;
  display: block;
  font-size: 0.85rem;
  font-weight: 700;
  text-transform: uppercase;
  color: #507025;
}

h2 {
  margin: 1.5rem 0;
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
  font-family: "Nunito Sans", sans-serif;
  color: #000000;
  -ms-word-wrap: break-word;
  word-wrap: break-word;
}

h2 .featured {
  position: relative;
  display: inline-block;
  padding: 5px 15px;
  color: #000;
}

.odd h2 .featured {
  color: #ffffff;
}

h2 .featured:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  background-color: #000;
  opacity: 0.15;
}

.odd h2 .featured:before {
  background-color: #507025;
}

h2 .featured span {
  position: relative;
}

h2 .featured.bottom {
  padding: 0;
}

h2 .featured.bottom:before {
  top: 50%;
  left: 3px;
  width: 100%;
  height: 42%;
  opacity: 0.15;
}

.odd h2 .featured.bottom:before {
  opacity: 0.5;
}

.title-icon {
  margin: 1.5rem 0;
}

.title-icon h2 .featured span {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title-icon h2 .featured span .icon {
  margin-right: 10px;
}

.odd h2 {
  color: #ffffff;
}

h3 {
  margin: 1.5rem 0;
  font-size: 2rem;
  font-weight: 300;
  line-height: 1.2;
  font-family: "Nunito Sans", sans-serif;
  color: #000000;
  -ms-word-wrap: break-word;
  word-wrap: break-word;
}

.odd h3 {
  color: #ffffff;
}

h4 {
  margin: 1.5rem 0;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.2;
  color: #000000;
  -ms-word-wrap: break-word;
  word-wrap: break-word;
}

h4 i {
  position: relative;
  display: inline-flex;
  vertical-align: baseline;
  font-size: 1.5rem;
  color: #000;
}

.odd h4 {
  color: #ffffff;
}

.odd h4 i {
  position: relative;
  color: #507025;
}

h5 {
  margin: 1.5rem 0;
  font-size: 1.2rem;
  font-weight: 300;
  line-height: 1.2;
  font-family: "Nunito Sans", sans-serif;
  color: #000000;
  -ms-word-wrap: break-word;
  word-wrap: break-word;
}

.odd h5 {
  color: #ffffff;
}

h6 {
  margin: 1.5rem 0;
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.2;
  font-family: "Nunito Sans", sans-serif;
  color: #000000;
  -ms-word-wrap: break-word;
  word-wrap: break-word;
}

.odd h6 {
  color: #ffffff;
}

b,
strong {
  font-weight: 700;
}

li {
  /* margin: 0.5rem 0; */
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

table {
  border-collapse: separate;
  border-spacing: 10px;
}

table td {
  padding: 10px 0;
}

table th {
  padding: 10px 0;
}

dt {
  margin: 15px 0;
}

address {
  margin-bottom: 1rem;
}

.container {
  max-width: 1170px;
  padding: 0;
}

.container.header {
  max-width: 1140px;
}

.container.header.full {
  max-width: 100%;
}

.container.header.full-grid {
  max-width: 100%;
  margin: 0 5px;
}

.container.header.smaller {
  max-width: 940px;
}

.container.disabled {
  width: auto;
  max-width: 100%;
}

.container.disabled .content-inner {
  padding: 0;
}

.container.full {
  max-width: 100%;
}

.container.full-grid {
  padding: 0;
  max-width: calc(100% - 30px);
  margin: 0 15px;
}

.container.smaller {
  max-width: 970px;
}

form {
  width: 100%;
}

form label {
  width: 100%;
}

input,
select {
  width: 100%;
  height: 44px;
  background: no-repeat;
  box-shadow: none;
  padding: 0.7rem 1rem;
  border-color: rgba(0, 0, 0, 0.25);
  border-width: 1px;
  border-radius: 2px;
}

textarea {
  width: 100%;
  height: auto;
  background: no-repeat;
  box-shadow: none;
  padding: 0.7rem 1rem;
  border-color: rgba(0, 0, 0, 0.25);
  border-width: 1px;
  border-radius: 2px;
}

input:focus,
textarea:focus,
select:focus {
  background: no-repeat;
  color: inherit;
  outline: none;
  border-color: inherit;
  box-shadow: none;
}

input {
  border-color: rgba(0, 0, 0, 0.25);
  color: #000000;
}

input:focus {
  border-color: #000000;
  color: #000000;
}

input[type="radio"] {
  height: initial;
}

input[type="submit"] {
  border: 1px solid;
  padding: 2.5px 10px;
  display: block;
  width: fit-content;
  max-width: 200px;
  text-align: center;
  color: #000;
}

input[type="submit"]:hover {
  background-color: #000;
  color: #f9f9f9;
  border-color: #000;
}

input[type="checkbox"] {
  width: 20px;
  height: 20px;
}

textarea {
  height: 100px;
  resize: none;
  border-color: rgba(0, 0, 0, 0.25);
  color: #000000;
}

textarea:focus {
  border-color: #000000;
  color: #000000;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: "";
  line-height: 1.2;
  border-color: rgba(0, 0, 0, 0.25);
  color: #6c757d;
}

select:focus {
  border-color: #000000;
  color: #000000;
}

select option {
  color: #2f323a;
}

table {
  width: 100%;
}

tr:not(:last-child) {
  border-bottom: 1px solid;
  border-color: rgba(0, 0, 0, 0.25);
}

.odd tr:not(:last-child) {
  border-bottom: 1px solid;
  border-color: rgba(255, 255, 255, 0.35);
}

td {
  padding: 10px 0;
}

section {
  position: relative;
  width: 100%;
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
}

/* section.hero {
  background-color: var(--hero-bg-color);
} */

section.featured {
  position: relative;
}

section.featured.right:before {
  content: "";
  position: absolute;
  z-index: 1;
  top: 100%;
  right: 0;
  width: 50%;
  border-bottom: 1px dashed rgba(0, 0, 0, 0.25);
}

section.odd.featured.right:before {
  border-bottom: 1px dashed rgba(255, 255, 255, 0.35);
}

section.featured.left:before {
  content: "";
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 0;
  width: 50%;
  border-bottom: 1px dashed rgba(0, 0, 0, 0.25);
}

section.odd.featured.left:before {
  border-bottom: 1px dashed rgba(255, 255, 255, 0.35);
}

section.featured.all:before {
  content: "";
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 0;
  width: 100%;
  border-bottom: 1px dashed rgba(0, 0, 0, 0.25);
}

section.featured.right .odd:before {
  content: "";
  position: absolute;
  z-index: 1;
  top: calc(100% - 1px);
  right: 0;
  width: 50%;
  border-bottom: 1px dashed rgba(255, 255, 255, 0.35);
}

section.featured.left .odd:before {
  content: "";
  position: absolute;
  z-index: 1;
  top: calc(100% - 1px);
  left: 0;
  width: 50%;
  border-bottom: 1px dashed rgba(255, 255, 255, 0.35);
}

section.featured.all .odd:before {
  content: "";
  position: absolute;
  z-index: 1;
  top: calc(100% - 1px);
  left: 0;
  width: 100%;
  border-bottom: 1px dashed rgba(255, 255, 255, 0.35);
}

section.odd.featured.all:before {
  border-bottom: 1px dashed rgba(255, 255, 255, 0.35);
}

main {
  padding: 0;
}

aside {
  padding: 0;
}

.container {
  max-width: 1170px;
  padding: 0;
}

.container.header {
  max-width: 1140px;
}

.container.header.full {
  max-width: 100%;
}

.container.header.full-grid {
  max-width: 100%;
  margin: 0 5px;
}

.container.header.smaller {
  max-width: 940px;
}

.container.disabled {
  width: auto;
  max-width: 100%;
}

.container.disabled .content-inner {
  padding: 0;
}

.container.full {
  max-width: 100%;
}

.container.full-grid {
  padding: 0;
  max-width: calc(100% - 30px);
  margin: 0 15px;
}

.container.smaller {
  max-width: 970px;
}

.row {
  margin-left: 0;
  margin-right: 0;
}

.vh-100 {
  height: 100vh;
}

.vh-75 {
  height: 75vh;
}

.clearfix {
  display: block;
  content: "";
  clear: both;
}

.overflow-holder {
  overflow: hidden;
  margin-top: -25px;
  margin-bottom: -25px;
  padding-top: 25px;
  padding-bottom: 25px;
}

.intro {
  margin-bottom: 50px;
}

.intro h1:first-child,
.intro h2:first-child,
.intro h3:first-child {
  margin-top: 0;
}

.intro .text-max-800 {
  margin-right: auto;
  margin-left: auto;
}

.blog-listing .items {
  margin-bottom: 30px;
}

.blog-listing .items .item {
  margin-bottom: 30px;
}

.blog-grid:not(.masonry) .items {
  margin-bottom: 30px;
}

.blog-grid:not(.masonry) .items .item {
  margin-bottom: 30px;
}

.blog-grid:not(.masonry) .items .item.sticky .card:before {
  top: -10px;
  right: 0;
  bottom: 0;
  left: -10px;
  position: absolute;
  z-index: -1;
  content: "";
  width: calc(50% + 10px);
  height: calc(50% + 10px);
  border: none;
  background-color: #000;
  transition-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75);
  transition-duration: 0.4s;
  transition-property: opacity, transform;
}

.post-meta {
  margin: 35px 0 0;
}

.post-meta span {
  margin-right: 10px;
  display: inline-block;
  font-size: 1rem;
  color: #000;
}

.post-meta i {
  width: 40px;
  height: 40px;
  margin-right: 5px;
  display: inline-block;
  background: rgba(5, 130, 131, 0.15);
  border-radius: 100px;
  text-align: center;
  line-height: 40px;
  font-size: 18px;
  color: #000;
}

.post-holder {
  list-style: none;
  width: 100%;
  border-left: 5px solid rgba(5, 130, 131, 0.15);
  background-color: #ffffff;
  padding: 25px;
  margin-top: 35px;
}

.posted-on a:not(.btn) {
  font-weight: 700;
}

.sidebar .item {
  margin-bottom: 50px;
}

.sidebar ol,
.sidebar ul {
  list-style: none;
  padding: 0;
}

.sidebar ol ol,
.sidebar ul ul {
  padding: 15px;
}

.sidebar ul.children {
  padding: 0;
}

.sidebar .sub-menu {
  padding: 0;
}

.sidebar ul li:not(.nav-item):not(.list-group-item) {
  position: relative;
  padding-left: 0;
}

.sidebar ul ul li:not(.nav-item):not(.list-group-item) {
  padding-left: 20px;
}

.sidebar li:hover:before {
  background-color: #000;
}

.sidebar ul li a:not(.btn) {
  color: inherit;
  overflow-wrap: break-word;
}

.sidebar ul li a:not(.btn):hover {
  color: #000;
}

.sidebar .title {
  width: 100%;
  margin-top: 0;
  list-style: none;
}

.list-group .list-group-item .icon {
  color: #000;
  margin-right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.highlights.image-right .image {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
}

.highlights.image-left .image {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
}

.highlights .items .item {
  margin-bottom: 35px;
}

.highlights .items .item:last-child {
  margin-bottom: 0;
}

.highlights p {
  white-space: pre-wrap;
}

.highlights .items .item .icon {
  color: #000;
}

.offers .items .item {
  margin-bottom: 30px;
}

.offers .items .item .icon {
  margin: 0;
  color: #000;
}

.offers .items .item .icon.featured {
  width: 65px;
  height: 65px;
  background: rgba(5, 130, 131, 0.15);
  border-radius: 100px;
  text-align: center;
  line-height: 65px;
  font-size: 32px;
}

.offers.secondary .items .item h4 {
  margin-top: 0;
}

.offers .card:hover .btn-icon {
  opacity: 1;
}

.images .image-over {
  margin-bottom: -1px;
  border-radius: 10px 10px 0 0;
}

.images .items .item .content {
  border-radius: 0 0 10px 10px;
  border: 1px solid rgba(0, 0, 0, 0.15);
}

.images .items .item .content p {
  margin: 1.5rem 0;
}

.team .items .item {
  margin-bottom: 50px;
}

.team .items h4 {
  margin: 0 0 10px;
}

.team .items p {
  margin: 10px 0;
  font-size: 14px;
  font-weight: 500;
}

.team .share-list .nav-item {
  margin: 0;
}

.team .items .share-list .nav-link {
  padding: 0;
}

.team .items .share-list .nav-link:hover i {
  opacity: 1;
}

.team .quote {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 35px;
  background-color: #000;
  border-radius: 4px;
  color: #ffffff;
}

.team .quote h4 {
  margin-top: 0;
  color: #ffffff;
}

.team .quote p {
  opacity: 0.85;
}

.team .quote h5 {
  margin-bottom: 0;
  font-family: "Nunito Sans", sans-serif;
  font-size: 1rem;
  color: #ffffff;
  opacity: 0.85;
}

.team .quote .quote-left {
  position: absolute;
  top: 35px;
  left: 50px;
  font-size: 1.5rem;
  color: rgba(0, 0, 0, 0.25);
}

.team .quote .quote-right {
  position: absolute;
  bottom: 35px;
  right: 50px;
  font-size: 1.5rem;
  color: rgba(0, 0, 0, 0.25);
}

.team .quote .list-group-item {
  border-color: rgba(255, 255, 255, 0.35);
}

.team .quote .list-group .list-group-item .icon {
  color: #ffffff;
}

.testimonials .card {
  padding: 35px 25px;
  background-color: #ffffff;
}

.testimonials .card:not(.no-hover):hover {
  transform: none;
}

.odd.testimonials .card:not(.no-hover):hover p,
.odd.testimonials .card:not(.no-hover):hover i {
  color: inherit;
}

.testimonials .card .logo {
  width: auto;
  max-width: 100%;
  height: 25px;
  margin-right: auto;
  margin-bottom: 1.5rem;
  display: block;
}

.testimonials .card .quote-right {
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: 1.5rem;
  color: rgba(0, 0, 0, 0.2);
}

.odd.testimonials .card .quote-right {
  color: rgba(255, 255, 255, 0.2);
}

.partners img {
  width: auto;
  max-width: 100%;
  height: 25px;
  opacity: 0.5;
  -webkit-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.partners img:hover {
  opacity: 1;
}

.odd.partners img {
  filter: brightness(0) invert(1);
}

.projects .btn-icon {
  color: #ffffff;
  opacity: 0;
}

.projects .card:hover .btn-icon {
  opacity: 1;
}

.process .items {
  position: relative;
  cursor: crosshair;
}

.process .items:before {
  content: "";
  position: absolute;
  z-index: 0;
  top: 21px;
  left: 0;
  width: 100%;
  height: 5px;
  background-color: rgba(5, 130, 131, 0.15);
}

.process .step {
  position: relative;
  width: 45px;
  height: 45px;
  margin: auto;
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 45px;
  color: #000;
}

.process .step:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  background-color: #daeced;
  -webkit-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.process .step span {
  position: relative;
}

.process .items .item:hover .step span {
  color: #ffffff;
}

.process .items .item:hover .step:before {
  background-color: #000;
}

.counter.skills .items .item {
  padding: 35px 0 10px;
}

.counter .radial {
  position: relative;
  width: 110px;
  display: inline-block;
  text-align: center;
}

.counter .radial.left {
  width: auto;
  text-align: left;
}

.counter .radial canvas {
  height: 70px;
  vertical-align: middle;
  visibility: hidden;
}

.counter.skills .radial canvas {
  height: auto;
  visibility: visible;
}

.counter .radial span {
  position: absolute;
  top: 35px;
  left: 0;
  width: 100%;
  text-align: center;
  line-height: 40px;
  font-size: 2rem;
  font-weight: 700;
}

.counter.preloader .radial span {
  font-size: 2.5rem;
  color: #ffffff;
}

.counter.funfacts .radial span {
  font-size: 2.5rem;
  color: #507025;
}

.counter .radial span i {
  font-style: normal;
  font-size: 1rem;
  font-weight: 700;
}

.plans .items .item {
  margin-bottom: 30px;
}

.plans .items .item .card:hover .btn-icon {
  opacity: 1;
}

.plans .items .item .icon {
  color: #000;
}

.plans .items .item .btn {
  margin-top: 25px;
}

.plans .items .item .list-group-item {
  padding: 10px 0;
  border: none;
}

.plans .items .item .card:hover {
  transform: translateY(-7.5px);
}

.plans .items .item .card:hover .btn {
  background: none;
  border: 1px solid rgba(255, 255, 255, 0.35);
}

.plans .items .item .card:hover .btn:after,
.plans .items .item .card:hover .btn:before {
  content: none;
}

.plans .items .item .card:hover * {
  border-color: rgba(255, 255, 255, 0.35);
}

.plans .items .item h4 {
  margin: 1rem 0;
}

.plans .items .item .most-popular {
  padding: 60px 45px;
}

.plans .items .item .badge {
  position: absolute;
  top: 15px;
  left: 0;
  padding: 7.5px 15px 7.5px 10px;
  border-radius: 0 100px 100px 0px;
  text-align: left;
  font-size: 13px;
  font-weight: 500;
  color: #ffffff;
  background: #507025;
}

.plans .icon-min {
  font-size: 1rem;
  color: #000;
}

.choose-plan {
  position: absolute;
  top: 25px;
  right: 25px;
}

.choose-plan .btn-icon {
  position: relative;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
}

.price {
  margin-bottom: 1rem;
  font-size: 42px;
  font-weight: 700;
  color: #000;
}

.price i {
  margin: 0 5px 0;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
}

.price .plan {
  display: inline-block;
  margin: 0 0 0 5px;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1;
}

.invert {
  filter: brightness(0) invert(1);
}

.grayscale {
  filter: grayscale(1);
}

.showcase.masonry .card {
  background-color: #ffffff;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.1);
}

.showcase .items .item .card.no-image .image-over:before {
  background-color: #ffffff;
}

.showcase.filter-section .card:hover .card-caption {
  opacity: 1;
}

.showcase .card {
  overflow: hidden;
  height: auto;
  background-color: transparent;
}

.showcase .card:not(.no-hover):hover {
  transform: none;
}

.showcase .card .image-over {
  overflow: hidden;
}

.showcase .card .image-over:before {
  content: "";
  position: absolute;
  z-index: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #0c0c14;
  border-radius: 10px;
  opacity: 0.5;
  -webkit-transition: all 0.2s ease-out 0s;
  -o-transition: all 0.2s ease-out 0s;
  -moz-transition: all 0.2s ease-out 0s;
  transition: all 0.2s ease-out 0s;
}

.showcase .card .image-over img {
  position: relative;
  z-index: -1;
  height: auto;
  min-height: 360px;
  object-fit: cover;
  object-position: center;
  -webkit-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.showcase .card .card-body {
  padding: 50px 25px;
  transform: translateY(100px);
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -moz-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.showcase .card:hover .card-body {
  transform: translateY(0);
}

.showcase .card:hover .card-footer {
  transform: translateY(0);
  opacity: 1;
}

.showcase .card .card-body h4 {
  margin-bottom: 50px;
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -moz-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.showcase .card:hover .card-body h4 {
  margin-bottom: 1.5rem;
}

.showcase .card .card-body p {
  margin: 1.5rem 0 0;
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -moz-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  opacity: 0;
}

.showcase .card:hover .card-body p {
  opacity: 1;
}

.showcase .card .card-footer .btn-icon {
  margin: 0;
}

.showcase .card .card-footer .btn-icon {
  margin: 0;
  color: #ffffff;
  opacity: 1;
}

.showcase .card-caption {
  position: absolute;
  width: 100%;
  bottom: 0;
}

.showcase .no-image .card-caption {
  position: relative;
}

.showcase .card-caption *,
.showcase .card-caption *:not(.btn) {
  color: #ffffff;
}

.showcase .no-image .card-caption *,
.showcase .no-image .card-caption *:not(.btn) {
  color: #000000;
}

.showcase.odd .no-image .card-caption *,
.showcase.odd .no-image .card-caption *:not(.btn) {
  color: #ffffff;
}

.showcase .no-image .card-caption .card-body p {
  color: #181a1c;
}

.showcase.odd .no-image .card-caption .card-body p {
  color: #ffffff;
}

.showcase .card-footer {
  position: absolute;
  top: 0;
  width: 100%;
  margin: auto;
  padding: 50px 25px;
  border: none;
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -moz-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  opacity: 0;
  transform: translateY(-100px);
}

.showcase .card-footer a:not(.btn) {
  line-height: 1.2;
  text-align: center;
  color: #ffffff;
}

.showcase .no-image .card-footer {
  border-color: rgba(0, 0, 0, 0.15);
}

.showcase .card:hover .image-over:before {
  opacity: 0.75;
}

.showcase .card:hover .image-over .mask-radius-full {
  opacity: 0;
}

.showcase .card:hover .image-over img {
  transform: scale(1.1);
  filter: blur(2px);
}

.showcase .card:hover .image-over .play-video-full {
  transform: translateY(-15%);
}

.filter-section .btn-group {
  margin: 0 0 35px;
  display: block;
}

.filter-section .btn-group .btn {
  display: inline-flex;
  align-items: center;
  margin: 0 10px;
  padding: 10px;
  font-size: 1rem;
  font-weight: 500;
  text-transform: uppercase;
  color: #181a1c;
}

.odd.filter-section .btn-group .btn {
  color: #ffffff;
}

.filter-section .btn-group .btn.focus {
  outline: none;
  box-shadow: none;
}

.filter-section .btn-group .btn:hover {
  color: #000000;
}

.odd.filter-section .btn-group .btn:hover {
  color: #ffffff;
}

.filter-section .btn-group .btn.active {
  color: #000000;
}

.odd.filter-section .btn-group .btn.active {
  color: #ffffff;
}

.filter-section .btn-group .btn.active:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  background-color: #000;
  opacity: 0.15;
}

.filter-section .btn-group .btn i {
  margin: 0 10px 0;
}

.subscribe .items .item {
  padding: 0 10px;
}

.contacts h4 {
  margin-top: 0;
}

.contacts a:not(.btn) {
  padding: 5px 0;
  font-size: 1.2rem;
  font-weight: 400;
  color: #000;
}

.contacts li {
  margin: 6.1px 0;
}

.contacts i {
  width: 45px;
  height: 45px;
  background: rgba(5, 130, 131, 0.15);
  border-radius: 100px;
  text-align: center;
  line-height: 45px;
  font-size: 20px;
}

.form .message {
  position: absolute;
  top: 0;
  left: 15px;
  width: 100%;
  height: 100%;
  padding: 35px;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  transform: scale(0);
  border-radius: 5px;
  background-color: #ffffff;
  -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
}

.form .message .icon {
  margin: auto;
  color: #000;
}

.form .message h3 {
  color: #000;
}

.form .message .done {
  display: none;
}

.form .message.active {
  transform: scale(1);
}

.form-group-margin {
  margin: 0 -7.5px;
}

.widget-services .list-group .list-group-item .icon {
  width: 100px;
  height: 100px;
  margin-right: 25px;
  border-radius: 10px;
  background-color: rgba(5, 130, 131, 0.15);
}

.widget-services .list-group-item .h-img {
  width: auto;
  margin-right: 25px;
  padding: 7.5px;
}

.widget-author .items .item {
  margin-bottom: 0;
}

.widget-author .card {
  background-color: rgba(5, 130, 131, 0.15);
}

.widget-author .card:hover {
  transform: initial;
}

.widget-author .person {
  width: 128px;
  height: 128px;
  border-radius: 100%;
}

header {
  position: relative;
  z-index: 5;
}

.navbar-holder {
  position: relative;
  z-index: -100;
  min-height: auto;
  pointer-events: none;
  background-color: #000000;
}

.navbar {
  -webkit-transition: all 0.15s ease-out 0s !important;
  -o-transition: all 0.15s ease-out 0s !important;
  -moz-transition: all 0.15s ease-out 0s !important;
  transition: all 0.15s ease-out 0s !important;
}

.navbar.top {
  position: relative;
  z-index: 5;
  height: 50px;
  padding: 0;
  background-color: #000000;
}

.navbar.sub {
  top: 50px;
}

header .navbar-sticky {
  -webkit-transition: all 0.15s ease-out 0s !important;
  -o-transition: all 0.15s ease-out 0s !important;
  -moz-transition: all 0.15s ease-out 0s !important;
  transition: all 0.15s ease-out 0s !important;
}

header .navbar-sticky.sub.hidden {
  -webkit-transform: translate3d(0, -101%, 0) !important;
  -ms-transform: translate3d(0, -101%, 0) !important;
  -moz-transform: translate3d(0, -101%, 0) !important;
  transform: translate3d(0, -101%, 0) !important;
}

header .navbar-sticky.sub.visible {
  -webkit-transform: translate3d(0, 0, 0) !important;
  -ms-transform: translate3d(0, 0, 0) !important;
  -moz-transform: translate3d(0, 0, 0) !important;
  transform: translate3d(0, 0, 0) !important;
}

header .navbar-sticky.sub {
  top: 0;
  padding: 10px 25px;
  background-color: #000000;
  -webkit-box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.09);
  box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.09);
  -webkit-transform: translate3d(0, -101%, 0) !important;
  -ms-transform: translate3d(0, -101%, 0) !important;
  -moz-transform: translate3d(0, -101%, 0) !important;
  transform: translate3d(0, -101%, 0) !important;
}

header .navbar-expand {
  position: fixed;
  top: 0;
  z-index: 2;
  width: 100%;
  /* padding-bottom: 25px;
  padding-right: 20px;
  padding-left: 20px;
  padding-top: 5px; */
  padding: 20px 25px;
  /* background-color: #000000; */
}

header .navbar-expand.relative {
  position: relative;
}

header .navbar-expand .navbar-nav {
  display: inline-block;
}

header .navbar-expand .navbar-nav.toggle {
  display: none;
}

header .navbar-expand .navbar-nav.toggle i {
  font-size: 20px;
}

header .navbar-expand .navbar-brand {
  padding: 0;
  font-weight: 700;
  font-size: 1.2rem;
  line-height: 1;
  color: #ffffff;
}

header .navbar-expand .navbar-brand.light {
  color: #ffffff;
}

header .navbar-expand .navbar-brand i {
  color: #000;
}

header .navbar-expand .navbar-brand img {
  height: 35px;
}

header .navbar-expand .navbar-brand .brand {
  letter-spacing: 5px;
}

header .navbar-expand .navbar-brand .featured {
  position: relative;
  display: inline-block;
  padding: 13px 2px 14px 30px;
}

header .navbar-expand .navbar-brand .featured:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% + 7.5px);
  height: 100%;
  border-radius: 100px 0 0 100px;
  background-color: #507025;
  opacity: 0.15;
}

header .navbar-expand .navbar-brand .featured .first {
  position: relative;
  margin-right: -10px;
  color: #507025;
}

.navbar-expand .navbar-nav .nav-item {
  margin: 0;
  display: inline-flex;
}

.navbar-expand .navbar-nav ul .nav-item {
  display: block;
}

.navbar-expand.top .navbar-nav .nav-item a:not(.btn) {
  font-size: 0.85rem;
  font-weight: 400;
  color: #ffffff;
}

.navbar-expand.top .navbar-nav .nav-item a:not(.btn):hover {
  color: #507025;
}

.navbar-expand .navbar-nav .nav-item a:not(.btn) {
  position: relative;
  display: flex;
  align-items: center;
  font-size: 1rem;
  font-weight: 500;
}

.navbar-expand .navbar-nav .nav-link:not(.btn) {
  padding-right: 1rem;
  padding-left: 1rem;
  color: #ffffff;
  -webkit-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
}

.navbar-expand .navbar-nav.items .nav-link:not(.btn):not(.title):hover,
.navbar-expand .navbar-nav.icons .nav-link:not(.btn):not(.title):hover {
  color: #507025;
}

.navbar-expand .navbar-nav.items .nav-link.active:not(.btn):not(.title) {
  color: #507025;
}

.navbar-expand .navbar-nav .dropdown-menu .nav-link:not(.btn) {
  min-height: 35px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.35rem 1rem;
  font-size: 0.85rem;
  font-weight: 400;
  color: #000000;
}

.navbar-expand .navbar-nav .dropdown-menu .nav-link:not(.btn) span {
  width: 24px;
  height: 24px;
  margin-left: 10px;
  display: inline-block;
  border-radius: 100px;
  background-color: rgba(5, 130, 131, 0.15);
  line-height: 24px;
  text-align: center;
  font-weight: 500;
  color: #000;
}

.odd .navbar-expand .navbar-nav .dropdown-menu .nav-link:not(.btn) {
  color: #ffffff;
}

.navbar-expand .navbar-nav .dropdown-menu .nav-link.title {
  font-weight: 700;
  color: #000000;
  opacity: 0.8;
}

.navbar-expand .navbar-nav .dropdown-menu .nav-link:not(.btn):not(.title):before,
.navbar-expand .navbar-nav .dropdown-menu .nav-link:not(.btn):not(.title):after {
  content: none;
}

.navbar-expand .navbar-nav .dropdown-menu .nav-link:not(.btn):not(.title).active,
.navbar-expand .navbar-nav .dropdown-menu .nav-link:not(.btn):not(.title):hover {
  background-color: rgba(5, 130, 131, 0.15);
  color: #000;
}

.navbar-expand .navbar-nav .dropdown-menu .nav-link:not(.btn):not(.title):hover span {
  background-color: #ffffff;
}

.navbar-nav i.icon-arrow-down {
  position: relative;
  top: 1.5px;
  margin: 0 0 0 0.3rem;
  font-size: 12px;
  font-weight: 700;
}

.navbar-nav i.icon-arrow-right {
  position: relative;
  left: 5px;
  top: 0;
  margin: 0 0 0 0.3rem;
  font-size: 10px;
  font-weight: 700;
}

.navbar-expand .icons i {
  font-weight: 400;
}

.dropdown .dropdown-menu {
  background-color: #ffffff;
  width: max-content;
  min-width: 120px;
  display: block;
  visibility: hidden;
  opacity: 0;
  border: none;
  border-radius: 0;
  -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
}

.odd .dropdown .dropdown-menu {
  background-color: #080d10;
}

.dropdown:hover>.dropdown-menu {
  visibility: unset;
  opacity: 1;
  margin: 0;
  transition-timing-function: ease;
  transition-duration: 0.4s;
  transition-property: opacity, transform;
}

.dropdown-menu .dropdown-menu {
  position: absolute;
  top: -8px;
  left: 100%;
  border-radius: 0;
}

.dropdown-item.active,
.dropdown-item:hover,
.dropdown-item:active {
  color: inherit;
  text-decoration: none;
  background-color: inherit;
  box-shadow: none;
  outline: none;
}

.menu .items {
  margin-left: -5px;
  width: calc(100% + 10px);
}

.menu .navbar-nav {
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1;
}

.menu .nav-item {
  margin: 0;
}

.menu .dropdown .dropdown-menu {
  display: none;
  width: 100%;
  visibility: visible;
  opacity: 1;
  background: none;
  box-shadow: none;
  border: none;
  text-align: center;
  padding: 0 0 0 20px;
  margin: 0 0 15px;
  list-style: none;
  color: #ffffff;
}

.menu .dropdown .dropdown-menu.show {
  display: block;
}

.menu .dropdown-menu .dropdown-menu {
  position: relative;
  top: initial;
  left: initial;
}

.menu .dropdown .dropdown-menu .left,
.menu .dropdown .dropdown-menu .right {
  width: 50%;
}

.menu .nav-link:not(.btn) {
  font-size: 1rem;
  font-weight: 400;
  border: 1px solid rgba(0, 0, 0, 0.25);
  color: #181a1c;
  display: inline-block;
  width: calc(100% - 12px);
  vertical-align: top;
  padding: 10px 5px;
  margin: 0 5px;
  border-radius: 4px;
}

.odd .menu .nav-link:not(.btn) {
  border: 1px solid rgba(255, 255, 255, 0.35);
  color: #ffffff;
}

.menu .nav-link:not(.btn):hover,
.odd .menu .nav-link:not(.btn):hover {
  color: #000;
}

.menu .dropdown .dropdown-menu .nav-link.title {
  font-weight: 700;
  color: #000000;
  border: none;
  opacity: 0.8;
}

.odd .menu .dropdown .dropdown-menu .nav-link.title {
  color: #ffffff;
}

.menu .dropdown .dropdown-menu .nav-item .nav-link {
  font-size: 1rem;
  font-weight: 400;
  background: none;
  color: #181a1c;
  display: flex;
  justify-content: space-between;
  width: calc(100% - 12px);
  vertical-align: top;
  padding: 0;
  margin: 0 5px 10px;
  border-radius: 4px;
  text-align: left;
}

.menu .dropdown .dropdown-menu .nav-item .nav-link span {
  width: 18px;
  height: 18px;
  display: inline-block;
  border-radius: 100px;
  line-height: 18px;
  text-align: center;
  font-size: 13px;
  font-weight: 500;
}

.odd .menu .dropdown .dropdown-menu .nav-item .nav-link {
  color: #ffffff;
}

.menu .dropdown .dropdown-menu .nav-link:hover,
.odd .menu .dropdown .dropdown-menu .nav-link:hover {
  color: #000;
}

.menu .dropdown:first-child {
  margin-top: 0;
}

.menu .nav-link:first-child {
  padding-left: 0;
  padding-right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.5rem;
  font-weight: 700;
  border: none;
  color: #000000;
}

.odd .menu .nav-link:first-child {
  margin-bottom: 0;
  font-weight: 700;
  border: none;
  color: #ffffff;
}

.menu .dropdown-menu .dropdown>.nav-link:first-child {
  font-size: 1rem;
  font-weight: 700;
}

.menu .navbar-nav i.icon-arrow-down {
  font-size: 18px;
}

.slider-h-auto {
  height: auto;
  display: flex;
  align-items: center;
}

.slider-h-auto .swiper-wrapper,
.slider-h-auto .swiper-slide {
  min-height: 75vh;
}

.slider-h-auto.no-slider .inner {
  top: 0;
  padding-top: 100px;
  padding-bottom: 100px;
}

.slider-h-100 {
  width: 100%;
  height: calc(100vh - 50px);
}

.slider-h-100.slider-h-auto {
  min-height: calc(100vh - 50px);
}

.slider-h-85 {
  width: 100%;
  height: 85vh;
}

.slider-h-75 {
  width: 100%;
  height: 75vh;
}

.slider-h-75.slider-h-auto {
  min-height: 75vh;
}

.slider-h-70 {
  width: 100%;
  height: 70vh;
}

.slider-h-65 {
  width: 100%;
  height: 65vh;
}

.slider-h-60 {
  width: 100%;
  height: 60vh;
}

.slider-h-50 {
  width: 100%;
  height: 50vh;
}

.slider-h-50.slider-h-auto {
  min-height: 50vh;
}

.slider-h-25 {
  width: 100%;
  height: 25vh;
}

.slider-h-25.slider-h-auto {
  min-height: 25vh;
}

.hero .parallax-y-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
}

.hero .parallax-x-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 200%;
  height: 100%;
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center;
}

.hero.slider-parallax {
  position: fixed;
  top: 0;
  min-height: auto;
  -webkit-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
}

.slider-parallax-holder {
  position: relative;
  z-index: -100;
  min-height: 100vh;
  pointer-events: none;
}

.mid-slider {
  overflow: hidden;
  padding: 0 15px;
}

.mid-slider-simple {
  padding: 0 15px;
}

.mid-slider-simple .card:not(.no-hover):hover {
  transform: initial;
}

.full-slider {
  cursor: w-resize;
}

.full-slider .inner,
.no-slider .inner {
  top: 0;
  max-width: 1140px;
  padding: 0;
  margin: auto;
}

.no-slider.slider-h-75 .inner {
  top: 10px;
}

.full-slider .inner .center,
.no-slider .inner .center {
  width: 100%;
  padding: 3rem 0;
}

.full-slider .inner .left,
.no-slider .inner .left {
  width: 60%;
  padding: 3rem 0;
}

.full-slider .inner .right,
.no-slider .inner .right {
  width: 45%;
  padding: 3rem 0;
}

.full-slider.featured .inner .left:before,
.no-slider.featured .inner .left:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 5%;
  right: 0;
  bottom: 0;
  left: 5%;
  width: 45%;
  height: 95%;
  border-top: 4px solid;
  border-right: 4px solid;
  transform: rotate(-15deg);
  border-image: -webkit-linear-gradient(to right,
      transparent 55%,
      #000 55%,
      #000 100%) 30;
  border-image: linear-gradient(to right, transparent 55%, #000 55%, #000 100%) 30;
  transition-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75);
  transition-duration: 0.6s;
  transition-property: opacity, transform;
  opacity: 0;
}

.full-slider.featured .inner .left:after,
.no-slider.featured .inner .left:after {
  content: "";
  position: absolute;
  z-index: 1;
  top: 5%;
  right: 0;
  bottom: 0;
  left: 5%;
  width: 45%;
  height: 95%;
  border-left: 4px solid;
  border-bottom: 4px solid;
  transform: rotate(-15deg);
  border-image: -webkit-linear-gradient(to bottom,
      transparent 83%,
      #000 83%,
      #000 100%) 30;
  border-image: linear-gradient(to bottom, transparent 83%, #000 83%, #000 100%) 30;
  transition-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75);
  transition-duration: 0.6s;
  transition-property: opacity, transform;
  opacity: 0;
}

.full-slider.featured .inner .right:before,
.no-slider.featured .inner .right:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 5%;
  bottom: 0;
  right: 5%;
  width: 45%;
  height: 95%;
  border-top: 4px solid;
  border-right: 4px solid;
  transform: rotate(-15deg);
  border-image: -webkit-linear-gradient(to right,
      transparent 55%,
      #000 55%,
      #000 100%) 30;
  border-image: linear-gradient(to right, transparent 55%, #000 55%, #000 100%) 30;
  transition-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75);
  transition-duration: 0.6s;
  transition-property: opacity, transform;
  opacity: 0;
}

.full-slider.featured .inner .right:after,
.no-slider.featured .inner .right:after {
  content: "";
  position: absolute;
  z-index: 1;
  top: 5%;
  right: 5%;
  bottom: 0;
  width: 45%;
  height: 95%;
  border-left: 4px solid;
  border-bottom: 4px solid;
  transform: rotate(-15deg);
  border-image: -webkit-linear-gradient(to bottom,
      transparent 83%,
      #000 83%,
      #000 100%) 30;
  border-image: linear-gradient(to bottom, transparent 83%, #000 83%, #000 100%) 30;
  transition-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75);
  transition-duration: 0.6s;
  transition-property: opacity, transform;
  opacity: 0;
}

.full-slider .inner .left.off:before,
.full-slider .inner .left.off:after,
.full-slider .inner .right.off:before,
.full-slider .inner .right.off:after {
  content: none;
}

.full-slider .inner .left.init:before,
.full-slider .inner .left.init:after,
.no-slider .inner .left.init:before,
.no-slider .inner .left.init:after,
.full-slider .inner .right.init:before,
.full-slider .inner .right.init:after,
.no-slider .inner .right.init:before,
.no-slider .inner .right.init:after {
  opacity: 0;
}

.full-slider.featured .inner .left .title,
.no-slider.featured .inner .left .title,
.full-slider.featured .inner .right .title,
.no-slider.featured .inner .right .title {
  max-width: 85%;
}

.full-slider .slide-content .center .description,
.no-slider .slide-content .center .description {
  max-width: 800px;
  font-size: 1.2rem;
}

.full-slider .slide-content .center .description.smaller,
.no-slider .slide-content .center .description.smaller {
  max-width: 500px;
}

.full-slider .slide-content .left .description,
.no-slider .slide-content .left .description,
.full-slider .slide-content .right .description,
.no-slider .slide-content .right .description {
  max-width: 550px;
  margin-bottom: 1.5rem;
  font-size: 1.2rem;
}

.full-slider.featured .inner .right .description {
  right: 15%;
  position: relative;
}

.full-slider .slide-content .description.bigger,
.no-slider .slide-content .description.bigger {
  max-width: 100%;
  color: #000000;
  font-size: 1.5rem;
  font-weight: 400;
}

.odd .full-slider .slide-content .description.bigger,
.odd .no-slider .slide-content .description.bigger {
  color: #ffffff;
}

.full-slider .swiper-button-next {
  right: 1.5rem;
}

.full-slider .swiper-button-prev {
  left: 1.5rem;
}

.full-slider .swiper-button-next:focus,
.full-slider .swiper-button-prev:focus {
  outline: none;
}

.full-slider .swiper-button-next,
.full-slider .swiper-button-prev {
  top: calc(50% + 60px);
  background-image: -webkit-linear-gradient(45deg, #507025 0%, #000 55%);
  background-image: linear-gradient(45deg, #507025 0%, #000 55%);
  opacity: 0.25;
  padding: 2rem 1.5rem;
  transition-timing-function: ease-in;
  transition: 0.2s;
}

.full-slider .swiper-button-next:hover,
.full-slider .swiper-button-prev:hover {
  background-image: -webkit-linear-gradient(45deg, #507025 0% #000 55%);
  background-image: linear-gradient(45deg, #507025 0%, #000 55%);
}

.full-slider .swiper-button-next:after,
.full-slider .swiper-button-prev:after {
  font-size: 3rem;
}

.mid-slider {
  cursor: w-resize;
}

.min-slider {
  cursor: w-resize;
}

.slide-center {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.slide-content {
  position: relative;
  z-index: 1;
  width: 100%;
}

.swiper-slide .aos-animate {
  display: none;
}

.swiper-slide.swiper-slide-active .aos-animate {
  display: block;
}

.swiper-slide .fit-image.aos-init {
  opacity: 1;
}

.swiper-slide .fit-image.aos-animate {
  visibility: hidden;
  display: block;
}

.swiper-slide.swiper-slide-active .fit-image.aos-animate {
  visibility: visible;
  display: block;
  transform: translateZ(0) scale(1.2);
  transition-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75);
  transition-duration: 3s;
  transition-property: opacity, transform;
}

.full-slider.swiper-container-horizontal>.swiper-pagination-bullets {
  position: absolute;
  top: calc(50% - 50px);
  right: 40px;
  left: initial;
  width: fit-content;
}

.swiper-pagination-bullet {
  width: 10px;
  height: 35px;
  margin: 7.5px 0 !important;
  display: block;
  border-radius: 10px;
  background-color: transparent;
  border: 1px solid #000;
  opacity: 0.5;
  outline: none;
}

.swiper-pagination-bullet:hover {
  opacity: 1;
}

.swiper-pagination-bullet-active {
  background-color: #000;
  opacity: 1;
}

.swiper-pagination-bullet-active:hover {
  background-color: #000;
}

.pagination {
  width: fit-content;
  margin: auto;
  -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.pagination .page-item {
  margin: 0;
}

.pagination .page-item:focus {
  outline: none;
  box-shadow: none;
}

.pagination .page-item .page-link {
  width: 50px;
  height: 50px;
  line-height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 17px;
  font-weight: 400;
  background-color: #ffffff;
  border-radius: 2px;
  border: 1px solid;
  border-color: #ffffff;
  color: #000000;
}

.odd .pagination .page-item .page-link {
  background-color: #000000;
  border-color: #080d10;
}

.pagination .page-item.active .page-link {
  background-color: #000;
  border-color: #000;
  color: #ffffff;
}

.odd .pagination .page-item.active .page-link {
  background-color: #000000;
}

.pagination .page-item .page-link:hover {
  background-color: #000;
  border-color: #000;
  color: #ffffff;
}

.odd .pagination .page-item .page-link:hover {
  color: #000000;
}

.pagination .page-item .page-link:focus {
  outline: none;
  box-shadow: none;
}

.preloader {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0c0c14;
  transition-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75);
  transition-duration: 0.4s;
  transition-property: opacity, transform;
}

.preloader.ready {
  z-index: -100;
  opacity: 0;
}

.icon {
  width: 44px;
  height: 44px;
  line-height: 44px;
  text-align: center;
  font-size: 44px;
  display: block;
  margin: auto;
  -webkit-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
}

.icon.smaller {
  width: 24px;
  height: 24px;
  line-height: 24px;
  font-size: 24px;
}

.icon.bigger {
  width: 5rem;
  height: 5rem;
  margin: 0;
  font-size: 5rem;
  line-height: 5rem;
  font-weight: bold;
}

.icon.circle {
  border-radius: 100px;
  font-size: 28px;
}

.bricklayer {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.bricklayer-column-sizer {
  width: 33.3333%;
  display: none;
}

.columns-1 .bricklayer-column-sizer {
  width: 100%;
}

.columns-2 .bricklayer-column-sizer {
  width: 50%;
}

.columns-3 .bricklayer-column-sizer {
  width: 33.3333%;
}

.columns-4 .bricklayer-column-sizer {
  width: 25%;
}

.columns-5 .bricklayer-column-sizer {
  width: 20%;
}

.columns-6 .bricklayer-column-sizer {
  width: 16.6666%;
}

.bricklayer-column {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  margin: 0 15px;
}

.bricklayer .item {
  margin: 0 0 30px 0;
}

.card-columns {
  margin: 0 15px;
  column-gap: 30px;
}

.card-columns .card {
  margin-bottom: 30px;
}

.card {
  position: relative;
  z-index: 1;
  width: 100%;
  padding: 45px;
  border-radius: 10px;
  border: none;
  background-color: black;
  -webkit-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
}

.card.no-hover {
  background-color: transparent;
  box-shadow: none;
}

.odd:not(.custom) .card:not(.no-hover) {
  background-color: rgba(255, 255, 255, 0.035);
}

.card p {
  margin: 0 0 10px;
  white-space: pre-wrap;
  -webkit-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
}

.card-img-top {
  border-radius: 0;
}

.card-body {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  min-height: 1px;
  padding: 35px 30px;
}

.card-body h3,
.card-body h4,
.card-body h5,
.card-body h6 {
  margin-top: 0;
}

.card-footer {
  border-radius: 0;
  background: none;
  padding: 30px 0;
  margin: 0 30px;
  border-top: 1px solid rgba(0, 0, 0, 0.25);
}

.card-footer a {
  margin: 0 10px;
}

.card-footer a:not(.btn) {
  line-height: 1.2;
  text-align: left;
  color: #181a1c;
}

.card-footer i {
  margin: 0 10px 2.5px 0;
}

.card.simple {
  box-shadow: none;
  background: none;
  padding: 0 2rem;
}

.card.simple:hover {
  background: none;
}

.card:not(.no-hover):hover {
  transform: translateY(-7.5px);
}

.sticky.card:not(.no-hover) {
  background: -webkit-linear-gradient(45deg, #507025 15%, #000 65%);
  background: linear-gradient(45deg, #507025 15%, #000 65%);
}

.masonry .card:not(.no-hover):hover {
  transform: translateY(0);
}

.card:not(.no-hover):hover:before,
.card:not(.no-hover):hover:after {
  opacity: 0;
}

.card:hover .image-person img {
  transform: scale(1.1);
}

.odd .card:not(.no-hover):hover h3,
.odd .card:not(.no-hover):hover h4,
.odd .card:not(.no-hover):hover p {
  color: #ffffff;
}

.odd .card:not(.no-hover):hover span,
.odd .card.stycky:not(.no-hover) span {
  color: #ffffff;
}

.odd .card:not(.no-hover):hover a,
.odd .card.sticky:not(.no-hover) a {
  color: #ffffff;
}

.odd .card:not(.no-hover):hover i,
.odd .card.sticky:not(.no-hover) i {
  color: #ffffff;
}

.odd .card:not(.no-hover):hover .icon,
.card.sticky:not(.no-hover) .icon {
  background-color: transparent;
  color: #ffffff;
}

.odd.features .card:not(.no-hover):hover .icon {
  background-color: #000;
}

.odd .card:hover .card-footer,
.odd .card.sticky .card-footer {
  color: #ffffff;
  border-color: rgba(255, 255, 255, 0.25);
}

.odd .card:hover .btn-icon {
  opacity: 1;
  color: #ffffff;
}

.odd .card:hover .share-list i {
  color: #ffffff;
}

.showcase .card .card-body .badges {
  transform: translateY(100px);
}

.showcase .card:hover .card-body .badges {
  transform: translateY(0);
}

.card .badges a:first-child .badge {
  margin-bottom: 15px;
}

.card .badges .badge {
  width: 100%;
  margin: 0;
}

.badges {
  display: inline-block;
}

.badges .badge {
  margin: 0 10px 12.5px 0;
  -webkit-transition: all 0.2s ease-out 0s;
  -o-transition: all 0.2s ease-out 0s;
  -moz-transition: all 0.2s ease-out 0s;
  transition: all 0.2s ease-out 0s;
}

.badges .badge a:not(.btn) {
  line-height: 1;
}

.badges .badge:hover {
  color: #ffffff;
  background-color: #507025;
}

.badges .badge a:not(.btn):hover {
  color: #ffffff;
}

.badge {
  padding: 10px;
  font-size: 14px;
  font-weight: 500;
  color: #507025;
  background-color: rgba(5, 130, 131, 0.15);
}

.badge.circle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  line-height: 30px;
  border-radius: 100%;
  background-color: rgba(5, 130, 131, 0.15);
}

.badge.tag {
  margin: 0.3rem;
  padding: 0.5rem 1rem;
  border-radius: 2px;
  font-size: 1rem;
  font-weight: 400;
  color: #181a1c;
  border: 1px solid rgba(0, 0, 0, 0.25);
  -webkit-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
}

.badge.tag:hover,
.badge.tag.active {
  color: #ffffff;
  background-color: #000;
}

.breadcrumb {
  margin: 0;
  background: none;
  justify-content: center;
  color: inherit;
}

.breadcrumb-item {
  font-size: 1rem;
  font-weight: 400;
}

.breadcrumb-item a:not(.btn) {
  color: inherit;
}

.breadcrumb-item a:hover {
  color: #000;
}

.breadcrumb-item+.breadcrumb-item::before {
  font-weight: 400;
}

.breadcrumb-item.active {
  color: #507025;
}

.breadcrumb-item.active:hover {
  color: #507025;
}

.share-list {
  margin: 0.5rem 0 0 0;
  display: -webkit-inline-box;
}

.sidebar .share-list {
  margin: 0;
}

.share-list .nav-link i {
  width: 40px;
  height: 40px;
  margin-right: 5px;
  display: inline-block;
  background: rgba(5, 130, 131, 0.15);
  border-radius: 100px;
  text-align: center;
  line-height: 40px;
  font-size: 18px;
  color: #000;
  -webkit-transition: all 0.2s ease-out 0s;
  -o-transition: all 0.2s ease-out 0s;
  -moz-transition: all 0.2s ease-out 0s;
  transition: all 0.2s ease-out 0s;
}

.share-list .nav-link i:hover {
  background-color: #000;
  color: #ffffff;
}

.share-list li:first-child i {
  margin-left: 0;
}

.share-list .nav-item {
  margin: 0;
}

.share-list .nav-link {
  padding: 0;
}

.image-over {
  overflow: hidden;
  border-radius: 10px;
}

.image-person img {
  transition-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75);
  transition-duration: 0.4s;
}

.person {
  width: 100%;
  border-radius: 5px;
}

.square-image {
  max-width: 900px;
  margin: auto;
  border-radius: 4px;
  background: #000;
}

.square-image .icon {
  position: absolute;
  z-index: 1;
  color: #ffffff;
  opacity: 0.75;
  transition-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75);
  transition-duration: 0.3s;
  transition-property: opacity, transform;
}

.square-image .icon.clone {
  margin: -17.5px -10px 0;
  color: #507025;
  opacity: 0.75;
}

.square-image img {
  opacity: 0.85;
  transition-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75);
  transition-duration: 0.3s;
  transition-property: opacity, transform;
}

.square-image:hover img {
  opacity: 0.75;
  filter: none;
}

.square-image:hover .icon {
  transform: scale(1.1);
  opacity: 1;
}

.mask-radius {
  content: "";
  position: absolute;
  z-index: 1;
  right: 15px;
  bottom: 0;
  width: 58%;
  height: 58%;
  background-image: -webkit-linear-gradient(45deg, #507025 0%, #000 55%);
  background-image: linear-gradient(45deg, #507025 0%, #000 55%);
  border-radius: 100% 0 0 0;
  opacity: 0.25;
}

.mask-radius-full {
  content: "";
  position: absolute;
  z-index: 1;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-image: -webkit-linear-gradient(45deg, #507025 0%, #000 55%);
  background-image: linear-gradient(45deg, #507025 0%, #000 55%);
  opacity: 0.5;
}

.play-video {
  position: absolute;
  z-index: 2;
  right: 15%;
  bottom: 15%;
  padding: 1.75rem 1.5rem 1.75rem 2rem;
  border-radius: 100%;
  font-size: 3rem;
  background-image: -webkit-linear-gradient(45deg, #507025 0%, #000 55%);
  background-image: linear-gradient(45deg, #507025 0%, #000 55%);
  background-size: 200% auto;
  color: #f9f9f9;
}

.play-video-full {
  position: absolute;
  z-index: 2;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: auto;
  height: auto;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  font-size: 4rem;
  color: #f9f9f9;
  -webkit-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
}

.full-image[data-mask="90"] {
  opacity: 0.1;
}

.full-image[data-mask="80"] {
  opacity: 0.2;
}

.full-image[data-mask="70"] {
  opacity: 0.3;
}

.full-image[data-mask="60"] {
  opacity: 0.4;
}

.full-image[data-mask="50"] {
  opacity: 0.5;
}

.full-image[data-mask="40"] {
  opacity: 0.6;
}

.full-image[data-mask="30"] {
  opacity: 0.7;
}

.full-image[data-mask="20"] {
  opacity: 0.8;
}

.full-image[data-mask="10"] {
  opacity: 0.9;
}

.full-image[data-mask="0"] {
  opacity: 1;
}

.full-image {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
}

.full-image.to-bottom {
  object-position: bottom;
}

.hero-image {
  position: absolute;
  right: 10%;
  bottom: 0;
  width: auto;
  height: 85%;
}

.hero-image-left {
  position: absolute;
  left: 10%;
  bottom: 0;
  width: auto;
  height: 85%;
}

.fit-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.fit-image.w-85 {
  width: 85%;
}

.fit-image.top {
  object-position: top;
}

.fit-image.bottom {
  object-position: bottom;
}

.btn {
  width: fit-content;
  max-width: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0.5rem 1.25rem;
  border-width: 0;
  border-radius: 2px;
}

.btn.smaller,
.btn.smaller:active,
.btn.smaller:focus {
  width: fit-content;
  box-sizing: content-box;
  margin: 0 5px;
  padding: 7.5px 12.5px;
  display: inline-block;
}

.btn.w-100 {
  max-width: 100%;
}

.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show>.btn-primary.dropdown-toggle:focus,
.btn:hover,
.btn:focus {
  outline: none;
  box-shadow: none;
}

.btn i {
  margin-right: 0.5rem;
}

.btn i.left {
  margin-right: 0;
  margin-left: 0.5rem;
}

.btn-icon {
  position: absolute;
  top: 43px;
  right: 45px;
  width: 24px;
  height: 24px;
  line-height: 24px;
  font-size: 24px;
  text-align: center;
  cursor: pointer;
  color: #000;
  opacity: 0.5;
  -webkit-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
}

.card:hover .btn-icon.pulse:after {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  animation: pulse 1.5s infinite;
  -webkit-animation: pulse 1.5s infinite;
}

.grecaptcha-badge {
  visibility: hidden;
  z-index: -100;
}

.form-control {
  width: 100%;
  height: 50px;
  background: no-repeat;
  box-shadow: none;
  padding: 1rem;
  background-color: rgba(0, 0, 0, 0.075);
  border: none;
  border-radius: 4px;
  line-height: 1.2;
  color: #000000;
}

.form-control.less-opacity {
  background-color: rgba(0, 0, 0, 0.75);
}

.odd .form-control {
  background-color: rgba(255, 255, 255, 0.05);
  color: #ffffff;
}

.odd .form-control.less-opacity {
  background-color: rgba(255, 255, 255, 0.5);
  color: #000000;
}

select.form-control {
  color: #6c757d;
}

.odd select.form-control {
  color: #6c757d;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: inherit;
  opacity: 0.75;
  cursor: no-drop;
}

.form-control:focus {
  background-color: rgba(0, 0, 0, 0.1);
  outline: none;
  color: #000000;
  border: none;
  box-shadow: none;
}

.form-control.less-opacity:focus {
  background-color: rgba(0, 0, 0, 0.9);
}

.odd .form-control:focus {
  background-color: rgba(255, 255, 255, 0.075);
  outline: none;
  color: #ffffff;
  border: none;
  box-shadow: none;
}

.odd .form-control.less-opacity:focus {
  background-color: rgba(255, 255, 255, 0.9);
  color: #000000;
}

.form-control.valid {
  background-color: rgba(0, 122, 77, 0.25);
}

.form-control.less-opacity.valid {
  background-color: rgba(0, 122, 77, 0.5);
  color: #ffffff;
}

.form-control.invalid {
  background-color: rgba(227, 79, 79, 0.25);
}

.form-control.less-opacity.invalid {
  background-color: rgba(227, 79, 79, 0.5);
  color: #ffffff;
}

textarea.form-control {
  width: 100%;
  min-height: 152px;
}

.input-group>.custom-select:not(:first-child),
.input-group>.form-control:not(:first-child) {
  border-radius: 2px;
}

.input-group {
  margin: 10px 0;
}

.input-group .icon-arrow-down {
  position: absolute;
  top: calc(50% - 8px);
  right: 8px;
  color: #000000;
}

.odd .input-group .icon-arrow-down {
  color: rgba(255, 255, 255, 0.35);
}

button:disabled {
  cursor: no-drop;
}

.form-alert {
  margin: 1rem 0;
  display: block;
  color: #e34f4f;
}

.form-alert.valid {
  color: #007a4d;
}

.form-alert.invalid {
  color: #e34f4f;
}

.multi-step-form {
  min-height: 575px;
}

.multi-step-form fieldset {
  width: 100%;
  position: relative;
}

.multi-step-form fieldset:not(:first-of-type) {
  display: none;
}

.multi-step-form input {
  width: 100%;
}

.multi-step-form .mask-radius {
  width: calc(58% + 15px);
  right: 0;
}

.multi-step-form .step-images {
  min-height: 575px;
}

.multi-step-form .content-images .step-image .fit-image {
  min-height: 575px;
}

.progressbar {
  margin-bottom: 25px;
  padding: 0;
  overflow: hidden;
  counter-reset: step;
}

.progressbar:after {
  content: "";
  width: 100%;
  height: 6px;
  background-color: rgba(5, 130, 131, 0.15);
  position: absolute;
  right: 0;
  top: 25px;
  z-index: 0;
}

.odd .progressbar:after {
  background-color: #191919;
}

.progressbar.complete:after {
  background: linear-gradient(to right,
      #000 20%,
      #507025 40%,
      #507025 60%,
      #000 80%);
  background-size: 200% auto;
}

.progressbar li.active:before,
.progressbar li.active:after {
  background: linear-gradient(to right,
      #000 20%,
      #507025 40%,
      #507025 60%,
      #000 80%);
  background-size: 200% auto;
  -webkit-animation: effect 1s linear infinite;
  animation: effect 1s linear infinite;
  color: #ffffff;
}

.odd .progressbar li.active:before,
.progressbar li.active:after {
  color: #000000;
}

.progressbar li {
  list-style-type: none;
  font-size: 1rem;
  font-weight: 700;
  text-align: center;
  width: 33.3333%;
  float: left;
  position: relative;
  color: #000000;
}

form[data-steps="1"] .progressbar {
  display: none;
}

form[data-steps="1"] .progressbar li {
  width: 100%;
}

form[data-steps="2"] .progressbar li {
  width: 50%;
}

form[data-steps="3"] .progressbar li {
  width: 33.3333%;
}

.odd .progressbar li {
  color: #ffffff;
}

.progressbar li:before {
  position: relative;
  z-index: 2;
  content: counter(step);
  counter-increment: step;
  width: 40px;
  height: 40px;
  line-height: 40px;
  display: block;
  font-size: 1.2rem;
  font-weight: 700;
  text-align: center;
  color: #000000;
  background-color: rgba(5, 130, 131, 0.15);
  border-radius: 100px;
  margin: 0 auto 10px auto;
}

.odd .progressbar li:before {
  color: #ffffff;
  background-color: #191919;
}

.progressbar li:after {
  content: "";
  width: 100%;
  height: 6px;
  background-color: rgba(5, 130, 131, 0.15);
  position: absolute;
  left: -50%;
  top: 17px;
  z-index: 1;
}

.odd .progressbar li:after {
  background-color: #191919;
}

.list-group-item {
  margin: 0;
  background: none;
  font-size: 1rem;
  padding-right: 0;
  padding-left: 0;
  border-color: rgba(0, 0, 0, 0.25);
}

.odd .list-group-item {
  border-color: rgba(255, 255, 255, 0.35);
}

.list-group-item a:hover {
  color: #000;
}

.list-group-item img {
  width: 65px;
  height: 65px;
  display: block;
  margin-right: 50px;
  border-radius: 4px;
  background-color: rgba(5, 130, 131, 0.15);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
}

.odd .list-group-item img {
  background-color: #191919;
}

.list-group-item a:not(.btn) {
  color: inherit;
}

.list-group-item .icon {
  font-size: 34px;
  text-align: left;
}

.list-group-item:last-child .list-group-content {
  padding-bottom: 0;
}

.list-group-content {
  padding: 10px 0;
}

.list-group-content h4 {
  margin: 0 0 5px;
}

.list-group-content p {
  margin: 0;
}

.gallery {
  position: relative;
  overflow: hidden;
}

.content .gallery {
  margin: 35px 0 0 0;
}

.gallery .item:not(:last-child) {
  margin-bottom: 30px;
}

.gallery img {
  border-radius: 4px;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
}

.gallery .fit-image {
  width: 100%;
  height: 100%;
}

.gallery img:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.gallery a:hover img {
  -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
}

.gallery.featured {
  transform: scale(1);
}

.gallery.featured a:first-child:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: -30px;
  left: -15px;
  width: 90%;
  height: 90%;
  border: 4px solid;
  border-color: #000;
  transition-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75);
  transition-duration: 0.6s;
  transition-property: opacity, transform;
}

.gallery.featured a:last-child:before {
  content: "";
  position: absolute;
  z-index: -1;
  right: -15px;
  bottom: 0;
  width: 90%;
  height: 90%;
  border: 4px solid;
  border-color: #000;
  transition-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75);
  transition-duration: 0.6s;
  transition-property: opacity, transform;
}

footer {
  position: relative;
  background-color: #000000;
}

footer.odd {
  background-color: #111111;
}

footer .card h4 {
  margin-top: 0;
}

footer .card i {
  margin-right: 10px;
}

footer .items .card a:not(.btn) {
  margin: 0 0 0.5rem;
  display: block;
  color: #181a1c;
}

footer .odd .items .card a:not(.btn):last-child {
  margin-bottom: 5px;
}

footer .odd .items .card a:not(.btn) {
  color: #ffffff;
}

footer .items .card:not(.no-hover):hover a:not(.btn):hover {
  color: #507025;
}

footer .items .card a:not(.btn):hover i {
  color: #507025;
}

footer a.navbar-brand:not(.btn) {
  padding: 0;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 1;
  color: #ffffff;
}

footer .navbar-brand i {
  color: #000;
}

footer .navbar-brand img {
  height: 35px;
}

footer .navbar-brand .brand {
  letter-spacing: 5px;
  color: #ffffff;
}

footer .navbar-brand .featured {
  position: relative;
  display: inline-block;
  padding: 13px 2px 14px 30px;
}

footer .navbar-brand .featured:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% + 7.5px);
  height: 100%;
  border-radius: 100px 0 0 100px;
  background-color: #507025;
  opacity: 0.15;
}

footer .navbar-brand .featured .first {
  position: relative;
  margin-right: -10px;
  color: #507025;
}

footer .brand .logo {
  font-size: 2.5rem;
  font-weight: 700;
  line-height: 0.8;
  color: #000;
}

footer .brand .logo img {
  width: auto;
  height: 80px;
}

footer .brand a:hover {
  text-decoration: none;
}

footer .title {
  margin-top: 0;
}

footer .contacts {
  position: relative;
  top: 2.5rem;
  left: 2.5rem;
}

footer .contacts .nav-item {
  position: relative;
  z-index: 1;
}

footer .contacts:before {
  content: "";
  position: absolute;
  z-index: 0;
  top: -40px;
  right: 40px;
  width: 100%;
  height: 165%;
  border-top: 4px solid;
  border-right: 4px solid;
  border-color: #000000;
  transition-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75);
  transition-duration: 0.6s;
  transition-property: opacity, transform;
}

footer .contacts:after {
  content: "";
  position: absolute;
  z-index: 0;
  top: -40px;
  right: 40px;
  width: 100%;
  height: 165%;
  border-left: 4px solid;
  border-bottom: 4px solid;
  border-color: #000000;
  transition-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75);
  transition-duration: 0.6s;
  transition-property: opacity, transform;
}

footer .contacts:hover:before,
footer .contacts:hover:after {
  border-color: #000;
}

footer .contacts li {
  margin: 0;
  font-size: 1.2rem;
}

footer .nav-item {
  margin: 0;
}

footer .nav-item .nav-link {
  font-weight: 400;
  line-height: 1.4;
  color: inherit;
}

footer.odd .nav-item .nav-link {
  color: #ffffff;
}

footer .nav-item .nav-link:hover {
  color: #000;
  outline: none;
}

footer .copyright {
  background-color: rgba(0, 0, 0, 0.25);
}

footer .copyright p {
  font-size: 14px;
  line-height: 1.8;
}

.modal-open {
  overflow-y: hidden;
  overflow-x: hidden;
}

.modal-dialog-slideout {
  min-height: 100%;
  margin: 0 0 0 auto;
}

.modal.fade .modal-dialog.modal-dialog-slideout {
  -webkit-transform: translate(100%, 0) scale(1);
  transform: translate(100%, 0) scale(1);
  -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
  height: 100%;
}

.modal.fade.show .modal-dialog.modal-dialog-slideout {
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
  display: flex;
  align-items: start;
  -webkit-box-align: start;
  height: 100%;
}

.modal-content {
  border: none;
  border-radius: 0;
  overflow-x: hidden;
  background-color: #ffffff;
}

.odd .modal-content {
  background-color: #080d10;
}

.modal-content.full {
  height: 100%;
}

.modal-header {
  align-items: center;
  padding: 25px;
  border: none;
  font-weight: 700;
  color: #000000;
  border-radius: 0;
}

.odd .modal-header {
  color: #ffffff;
}

.modal-header .modal-title {
  line-height: 0;
}

.modal-header .icon-close {
  font-size: 24px;
  cursor: pointer;
}

.modal-header .icon-close:hover {
  color: #000;
}

.modal-header.absolute {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
}

.modal-header.absolute .icon-close {
  width: 45px;
  height: 45px;
  line-height: 45px;
  text-align: center;
  border-radius: 100px;
  background-color: rgba(5, 130, 131, 0.65);
  color: #ffffff;
  -webkit-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.modal-header.absolute .icon-close:hover {
  background-color: #000;
}

.modal-body {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 25px 25px 75px;
}

.modal-body iframe {
  width: 100%;
  height: 100%;
}

.spinner-grow {
  color: #000;
}

.scroll-to-top {
  display: none;
  position: fixed;
  z-index: 5;
  right: 25px;
  bottom: 25px;
  opacity: 0.75;
  transition-timing-function: ease;
  transition-duration: 0.4s;
  transition-property: opacity, transform;
}

.scroll-to-top:hover {
  opacity: 1;
}

.scroll-to-top i {
  width: 48px;
  height: 48px;
  line-height: 48px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  font-size: 22px;
  background-color: #ffffff;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.odd.scroll-to-top i {
  background-color: #080d10;
}

.scroll-to-top i:hover,
.scroll-to-top i:focus {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

@-webkit-keyframes effect {
  to {
    background-position: -200% center;
  }
}

@keyframes effect {
  to {
    background-position: -200% center;
  }
}

/* loading Fadup */
@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-in-up {
  animation-name: fadeInUp;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-delay: 0.2s;
}

.scroll-top::after {
  position: absolute;
  z-index: -1;
  content: "";
  top: 100%;
  left: 5%;
  height: 10px;
  width: 90%;
  opacity: 1;
  background: radial-gradient(ellipse at center,
      rgba(0, 0, 0, 0.25) 0%,
      rgba(0, 0, 0, 0) 80%);
}

.mobile-drawer .ant-drawer-content {
  background: #160042;
}

.container {
  width: 100% !important;
  padding: 0px 15px;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

@media (min-width: 1440px) {
  .container {
    max-width: 1320px;
  }
}

.list-wrap::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(255, 255, 255, 0.1);
  width: 100%;
  height: 1px;
}

.list-wrap::after {
  content: "";
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  background: rgba(255, 255, 255, 0.1);
  width: 1px;
  height: 100%;
}

.list-wrap>li {
  width: 50%;
}

.list-wrap-footer {
  margin-bottom: 40px;
}

.footer-contact {
  margin-bottom: 24px;
}

.list-wrap-footer>li,
.footer-contact>a {
  margin-bottom: 8px;
  list-style: none;
  font-size: 16px;
}

.list-wrap-footer>li>a:hover,
.footer-contact>a:hover {
  color: #C8B8E8 !important
}


/* Apply Plus Jakarta Sans to heading tags */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Plus Jakarta Sans', sans-serif !important;
  color: white !important
}

/* Apply Outfit to all other elements */
body,
p,
a,
li {
  font-family: 'Outfit', sans-serif;
  color: #C8B8E8;
}

.footer-social .list-wrap-social {
  display: flex;
  align-items: center;
  gap: 15px;
}

.footer-social .list-wrap-social li a {
  border: 1px solid #251740;
  border-radius: 5px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  color: white;
  justify-content: center;
}

.footer-social .list-wrap-social li a:hover {
  border-color: #6721FF;
  background: #6721FF;
  transition: all 300ms ease-in-out;
}

/* scrollUp */
.scroll-top {
  width: 35px;
  height: 35px;
  line-height: 35px;
  position: fixed;
  bottom: -10%;
  right: 30px;
  font-size: 16px;
  border-radius: 6px;
  z-index: 99;
  color: white;
  text-align: center;
  cursor: pointer;
  background: #6721FF;
  transition: 1s ease;
  border: none;
}

.scroll-top.open {
  bottom: 30px;
}

.scroll-top::after {
  position: absolute;
  z-index: -1;
  content: '';
  top: 100%;
  left: 5%;
  height: 10px;
  width: 90%;
  opacity: 1;
  background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0) 80%);
}

.scroll-top:hover {
  background: #00CBFF;
}


.header-action>ul {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.header-action>ul li {
  position: relative;
  margin-left: 40px;
}

.header-action ul li:first-child {
  margin-left: 0;
}

.header-action ul li a {
  color: white !important;
  font-size: 14px;
}

.header-action .header-btn .btn {
  color: white !important;
  font-size: 16px;
  padding: 17px 26px;
}

.header-action .header-btn .btn {
  color: white;
  font-size: 16px;
  padding: 17px 26px;
}

.btn {
  background: #6721FF;
  border: medium none;
  border-radius: 30px;
  color: white;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.05em;
  line-height: 1;
  margin-bottom: 0;
  padding: 19px 30px;
  text-align: center;
  text-transform: uppercase;
  touch-action: manipulation;
  transition: all 0.3s ease 0s;
  vertical-align: middle;
  white-space: nowrap;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.btn::before {
  content: "";
  position: absolute;
  -webkit-transition-duration: 800ms;
  transition-duration: 800ms;
  width: 200%;
  height: 200%;
  top: 110%;
  left: 50%;
  background: #00CBFF;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  border-radius: 50%;
  z-index: -1;
}

.btn:hover::before {
  top: -40%;
}

.rotate-arrow {
  display: inline-block;
  transform: rotate(-45deg);
  transition: transform 0.3s ease-in-out;
  margin-left: 20px;
}

.header-btn:hover .rotate-arrow {
  transform: rotate(0deg);
}

.btn:hover::after {
  transform: rotate(0);
}

.btn:hover {
  color: white;
}


/* header */

:root {
  --tg-body-font-family: 'Outfit', sans-serif;
  --tg-heading-font-family: 'Plus Jakarta Sans', sans-serif;
  --tg-playfair-font-family: 'Playfair Display', serif;
  --tg-primary-color: #6721FF;
  --tg-secondary-color: #00CBFF;
  --tg-gradient-color: linear-gradient(89.78deg, #C8BDFF -31.69%, #BAA6FF -22.78%, #6721FF 27.93%, #00CBFF 99.79%);
  --tg-gradient-color-two: linear-gradient(87.22deg, #C8BDFF -1.82%, #BAA6FF 5.99%, #6721FF 50.47%, #00CBFF 113.5%);
  --tg-gradient-color-three: linear-gradient(89.09deg, #FAEC60 -4.93%, #E5A34B 8.69%, #EE6E4D 20.84%, #F44380 37.75%,
      #BE3DB3 52.82%, #7746E6 73.27%, #5A71F1 89.06%, #439EFF 101.36%);
  ;
  --tg-body-font-color: #C8B8E8;
  --tg-heading-font-color: #FFFFFF;
  --tg-paragraph-color: #C8B8E8;
  --tg-body-font-size: 16px;
  --tg-body-font-weight: 400;
  --tg-heading-font-weight: 700;
  --tg-body-line-height: 1.75;
  --tg-heading-line-height: 1.2;
  --tg-blue: #270A5F;
  --tg-cetacean-blue: #12032F;
  --tg-indigo: #6610f2;
  --tg-purple: #6f42c1;
  --tg-pink: #d63384;
  --tg-red: #dc3545;
  --tg-orange: #fd7e14;
  --tg-yellow: #FDCF6D;
  --tg-green: #198754;
  --tg-teal: #20c997;
  --tg-cyan: #00C39A;
  --tg-white: #ffffff;
  --tg-black: #0D0126;
  --tg-black-two: #160042;
  --tg-black-three: #151515;
  --tg-black-four: #222325;
  --tg-gray: #F1F3FA;
  --tg-gray-two: #474C56;
  --tg-gray-three: #ABABAB;
  --tg-gray-four: #F0F0F0;
  --facebook: #3b5998;
  --twitter: #00acee;
  --linkedin: #1976d2;
  --pinterest: #3b5998;
  --youtube: #c4302b;
  --skype: #00aff0;
}

/*=============================
	02. Header
===============================*/
.custom-container {
  max-width: 1630px;
}

.transparent-header {
  position: absolute;
  left: 0;
  top: 0px;
  width: 100%;
  z-index: 9;
  height: auto;
}

.menu-area.transparent-header {
  padding: 15px 0;
}

.menu-nav {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.navbar-wrap {
  display: flex;
  flex-grow: 1;
}

.navbar-wrap ul {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0 0;
  margin: 0 auto;
}

.navbar-wrap ul li {
  list-style: none;
  display: block;
  position: relative;
}

.navbar-wrap ul li a {
  font-size: 16px;
  font-weight: 600;
  text-transform: capitalize;
  color: var(--tg-heading-font-color);
  padding: 40px 26px;
  display: block;
  line-height: 1;
  position: relative;
  z-index: 1;
  letter-spacing: 0.005em;
}

.navbar-wrap>ul>li.active>a,
.navbar-wrap>ul>li:hover>a {
  color: var(--tg-primary-color);
}

.main-menu .navigation li.menu-item-has-children .dropdown-btn {
  display: none;
}

.header-action>ul {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.header-action>ul li {
  position: relative;
  margin-left: 40px;
}

.header-action ul li:first-child {
  margin-left: 0;
}

.header-action ul li a {
  color: var(--tg-heading-font-color);
  font-size: 14px;
}

.header-action .header-btn .btn {
  color: var(--tg-white);
  font-size: 16px;
  padding: 17px 26px;
}

.header-action .header-lang .dropdown>button {
  border: none;
  background: transparent;
  padding: 0;
  color: var(--tg-white);
  font-weight: 400;
  font-size: 14px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
}

.header-action .header-lang img {
  max-width: 19px;
  margin-right: 5px;
  border-radius: 1px;
}

.header-lang .dropdown-menu {
  font-size: 14px;
  background: var(--tg-black-two);
  border: 1px solid rgb(255 255 255 / 15%);
  margin-top: 10px !important;
}

.header-action .header-lang .dropdown-item {
  align-items: center;
  display: flex;
  color: var(--tg-white);
  text-transform: uppercase;
}

.header-action .header-lang .dropdown-item:hover {
  background: var(--tg-primary-color);
}

.navbar-wrap ul li .sub-menu {
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  min-width: 230px;
  border: 1px solid rgba(255 255 255 / 15%);
  background: var(--tg-black-two);
  margin: 0 0;
  transform: scale(1, 0);
  transform-origin: 0 0;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-box-shadow: 0px 30px 70px 0px rgba(137, 139, 142, 0.15);
  -moz-box-shadow: 0px 30px 70px 0px rgba(137, 139, 142, 0.15);
  box-shadow: 0px 30px 70px 0px rgba(137, 139, 142, 0.15);
  border-radius: 5px;
  padding: 18px 0;
  display: block;
  visibility: hidden;
  opacity: 0;
  z-index: 9;
}

.navbar-wrap ul li .sub-menu .sub-menu {
  right: auto;
  left: 100%;
  top: 0;
}

.navbar-wrap ul li .sub-menu li {
  margin-left: 0;
  text-align: left;
  display: block;
}

.navbar-wrap ul li .sub-menu li a {
  padding: 9px 15px 9px 25px;
  line-height: 1.4;
  font-weight: 500;
  color: var(--tg-white);
  text-transform: capitalize;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.navbar-wrap ul li .sub-menu li.active a,
.navbar-wrap ul li .sub-menu li a:hover {
  background: var(--tg-primary-color);
}

.navbar-wrap ul li:hover>.sub-menu {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
}


@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.sticky-menu {
  position: fixed;
  left: 0;
  margin: auto;
  top: 0;
  width: 100%;
  z-index: 99;
  background: var(--tg-black-two);
  -webkit-animation: fadeInDown 1000ms ease-in-out 0s normal none 1 running;
  animation: fadeInDown 1000ms ease-in-out 0s normal none 1 running;
  -webkit-box-shadow: 0 10px 15px rgba(54, 54, 54, 0.1);
  box-shadow: 0 10px 15px rgba(54, 54, 54, 0.1);
  border-radius: 0;
}

#header-fixed-height.active-height {
  display: block;
  height: 105px;
}

/* megaMenu */
.tg-mega-menu-has-children {
  position: static !important;
}

.tg-mega-menu-wrap {
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  width: 1250px;
  box-shadow: -1px 23px 55px rgba(33, 33, 33, 0.1);
  border-radius: 20px;
  padding: 50px 40px 20px;
  background: var(--tg-white);
  margin: 0 0;
  transform: scale(1, 0);
  transform-origin: 0 0;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  margin: 0 auto;
  opacity: 0;
  visibility: hidden;
  z-index: 9;
}

.tg-mega-menu-has-children:hover .tg-mega-menu-wrap {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
}

.mega-menu-item {
  margin-bottom: 30px;
}

.mega-menu-item a {
  padding: 0 !important;
}

.mega-menu-thumb {
  overflow: hidden;
  margin-bottom: 30px;
}

.mega-menu-thumb img {
  transition: .3s linear;
  filter: drop-shadow(1px -2px 13px rgba(0, 0, 0, 0.15));
}

.mega-menu-thumb:hover img {
  transform: scale(1.1);
}

.mega-menu-content .title {
  margin-bottom: 0;
  text-align: center;
  color: var(--tg-black-two);
  letter-spacing: 0.02em;
  font-weight: 600;
  font-size: 18px;
  font-family: var(--tg-body-font-family);
}

.mega-menu-content .title a {
  color: var(--tg-black-two);
  letter-spacing: 0.02em;
  font-weight: 600;
  font-size: 18px;
  font-family: var(--tg-body-font-family);
}

.tg-mega-menu-wrap.black-bg .mega-menu-item.active .mega-menu-content .title a,
.mega-menu-item.active .mega-menu-content .title a,
.mega-menu-content .title a:hover {
  color: var(--tg-primary-color);
}

.tg-mega-menu-wrap.black-bg {
  background: var(--tg-black-two);
  box-shadow: -1px 23px 55px rgba(40 34 34 / 47%);
  border: 1px solid rgba(255 255 255 / 15%);
}

.tg-mega-menu-wrap.black-bg .mega-menu-content .title,
.tg-mega-menu-wrap.black-bg .mega-menu-content .title a {
  color: var(--tg-white);
}

.tg-mega-menu-wrap.black-bg .mega-menu-content .title a:hover {
  color: var(--tg-primary-color);
}

.tg-mega-menu-wrap.black-bg-two {
  background: var(--tg-black-three);
}

.tg-mega-menu-wrap.black-bg-two .mega-menu-item.active .mega-menu-content .title a,
.tg-mega-menu-wrap.black-bg-two .mega-menu-content .title a:hover {
  color: var(--tg-cyan);
}


/* header-two */
.header-top {
  background: var(--tg-gradient-color-three);
  padding: 8px 0 28px;
}

.header-top-content p {
  margin-bottom: 0;
  font-weight: 500;
  font-size: 14px;
  color: var(--tg-white);
}

.header-top-content p a {
  display: inline-flex;
  align-items: center;
  color: var(--tg-white);
  margin-left: 5px;
}

.header-top-content p a i {
  margin-left: 5px;
}

.header-top-content p a:hover {
  color: var(--tg-secondary-color);
}

.menu-area-two .navbar-wrap ul {
  display: inline-flex;
  background: var(--tg-gray);
  border: 1px solid var(--tg-gray);
  border-radius: 40px;
  padding: 0 57px;
}

.menu-area-two .navbar-wrap ul li a {
  color: var(--tg-black-two);
  padding: 15px 27px;
}

.menu-area-two .header-action .header-lang img {
  width: 24px;
  max-width: 24px;
  border-radius: 50%;
}

.menu-area-two .header-action .header-lang .dropdown>button {
  color: var(--tg-black-two);
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 0.14em;
  text-transform: uppercase;
}

.menu-area-two .header-action .header-btn .btn {
  background: var(--tg-black-two);
}

.menu-area-two .header-action .header-btn .btn::before {
  background: var(--tg-primary-color);
}

.menu-area.menu-area-two {
  padding: 27px 0;
  border-bottom: 1px solid var(--tg-gray);
  border-radius: 25px 25px 0 0;
  background: var(--tg-white);
  margin-top: -22px;
  z-index: 2;
  position: relative;
}

/* header-three */
.menu-area-three .header-action .header-lang img {
  max-width: 25px;
  border-radius: 50%;
}

.menu-area-three .navbar-wrap>ul>li.active>a,
.menu-area-three .navbar-wrap>ul>li:hover>a {
  color: var(--tg-cyan);
}

.menu-area-three .header-lang .dropdown-menu {
  background: var(--tg-black-four);
}

.menu-area-three .header-action .header-lang .dropdown-item:hover {
  background: var(--tg-cyan);
}

.menu-area.menu-area-three.transparent-header {
  padding: 0;
}

.menu-area.menu-area-three.sticky-menu {
  background: var(--tg-black-three);
}

.scroll-top.scroll-top-two {
  background: var(--tg-cyan);
}

.scroll-top.scroll-top-two:hover {
  background: var(--tg-black-four);
}

.menu-area-three .navbar-wrap ul li .sub-menu {
  background: var(--tg-black-three);
}

.menu-area-three .navbar-wrap ul li .sub-menu li.active a,
.menu-area-three .navbar-wrap ul li .sub-menu li a:hover {
  background: var(--tg-cyan);
}


/*=============================
	03. Mobile Menu
===============================*/
.nav-outer .mobile-nav-toggler {
  position: relative;
  float: right;
  font-size: 40px;
  line-height: 50px;
  cursor: pointer;
  display: none;
  color: var(--tg-white);
  margin-right: 30px;
  top: 15px;
}

.nav-logo img {
  max-width: 150px;
}

.mobile-menu {
  position: fixed;
  right: 0;
  top: 0;
  width: 300px;
  padding-right: 30px;
  max-width: 100%;
  height: 100%;
  z-index: 99;
  border-radius: 0px;
  transition: all 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -moz-transition: all 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -webkit-transition: all 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -ms-transition: all 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86)e;
  -o-transition: all 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -webkit-transform: translateX(101%);
  -ms-transform: translateX(101%);
  transform: translateX(101%);
}

.mobile-menu .navbar-collapse {
  display: block !important;
}

.mobile-menu .nav-logo {
  position: relative;
  padding: 30px 25px;
  text-align: left;
}

.mobile-menu-visible {
  overflow: hidden;
}

.mobile-menu-visible .mobile-menu {
  -webkit-transform: translateX(0%);
  -ms-transform: translateX(0%);
  transform: translateX(0%);
}

.mobile-menu .navigation li.current>a:before {
  height: 100%;
}

.menu-backdrop {
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  transition: all 700ms ease;
  -moz-transition: all 700ms ease;
  -webkit-transition: all 700ms ease;
  -ms-transition: all 700ms ease;
  -o-transition: all 700ms ease;
  opacity: 0;
  visibility: hidden;
  background: rgba(0, 0, 0, 0.5);
}

.mobile-menu-visible .menu-backdrop {
  opacity: 1;
  visibility: visible;
}

.mobile-menu .menu-box {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  max-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  background: var(--tg-black-two);
  padding: 0px 0px;
  z-index: 5;
  box-shadow: -9px 0 14px 0px rgb(0 0 0 / 6%);
}

.mobile-menu-visible .mobile-menu .menu-box {
  opacity: 1;
  visibility: visible;
}

.close-btn-mobile {
  position: absolute;
  right: 15px;
  top: 28px;
  line-height: 30px;
  width: 35px;
  text-align: center;
  font-size: 20px;
  color: var(--tg-white);
  cursor: pointer;
  z-index: 10;
  -webkit-transition: all 0.9s ease;
  -o-transition: all 0.9s ease;
  transition: all 0.9s ease;
}

.mobile-menu-visible .close-btn-mobile {
  -webkit-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  transform: rotate(360deg);
}

.mobile-menu .navigation {
  position: relative;
  display: block;
  width: 100%;
  float: none;
  margin: 0;
  padding: 0;
}

.mobile-menu .navigation ul {
  padding: 0;
  margin: 0;
}

.mobile-menu .navigation li {
  position: relative !important;
  display: block;
  border-top: 1px solid rgba(255 255 255 / 10%);
}

.mobile-menu .navigation:last-child {
  border-bottom: 1px solid rgba(255 255 255 / 10%);
}

.mobile-menu .navigation li>ul>li:first-child {
  border-top: 1px solid rgba(255 255 255 / 10%);
}

.mobile-menu .navigation li>a {
  position: relative;
  display: block;
  line-height: 24px;
  padding: 10px 60px 10px 25px;
  font-size: 16px;
  font-weight: 600;
  color: var(--tg-heading-font-color);
  text-transform: capitalize;
  -webkit-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
  border: none;
  letter-spacing: 0.005em;
}

.mobile-menu .navigation li ul li>a {
  font-size: 16px;
  margin-left: 20px;
  text-transform: capitalize;
}

.mobile-menu .navigation li ul li ul li a {
  margin-left: 40px;
}

.mobile-menu .navigation li ul li ul li ul li a {
  margin-left: 60px;
}

.mobile-menu .navigation li>a:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  height: 0;
  -webkit-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}

.mobile-menu .navigation li.menu-item-has-children .dropdown-btn {
  position: absolute;
  right: 15px;
  top: 6px;
  width: 32px;
  height: 32px;
  text-align: center;
  font-size: 16px;
  line-height: 32px;
  color: var(--tg-white);
  background: var(--tg-primary-color);
  cursor: pointer;
  border-radius: 2px;
  -webkit-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
  z-index: 5;
}

.mobile-menu .navigation li.menu-item-has-children .dropdown-btn i {
  -webkit-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}

.mobile-menu .navigation li.menu-item-has-children .dropdown-btn.open i {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  color: var(--tg-primary-color);
}

.mobile-menu .navigation li>ul,
.mobile-menu .navigation li>ul>li>ul {
  display: none;
}

.mobile-menu .social-links ul {
  display: flex;
  position: relative;
  text-align: center;
  padding: 30px 20px 20px;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.mobile-menu .social-links li {
  position: relative;
  display: inline-block;
  margin: 0px 6px 10px;
}

.mobile-menu .social-links li a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  position: relative;
  line-height: 32px;
  font-size: 16px;
  color: var(--tg-heading-font-color);
  -webkit-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
  border: 1px solid rgba(255 255 255 / 10%);
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
}

.mobile-menu .social-links li a:hover {
  border-color: var(--tg-primary-color);
  background: var(--tg-primary-color);
  color: var(--tg-white);
}

.menu-area .mobile-nav-toggler {
  position: relative;
  float: right;
  font-size: 27px;
  cursor: pointer;
  line-height: 1;
  color: var(--tg-primary-color);
  margin-top: -1px;
  padding: 5px 10px;
  border: 2px solid var(--tg-primary-color);
}

.mobile-menu .tg-mega-menu-wrap.black-bg,
.mobile-menu .tg-mega-menu-wrap {
  display: none;
  width: auto;
  padding: 25px 25px 0;
  background: transparent;
  position: relative;
  opacity: 1 !important;
  visibility: visible;
  transition: 0s;
  box-shadow: none;
  border: none;
  transform: scale(1);
  transform-origin: unset;
}

.mobile-menu .tg-mega-menu-wrap .row .col {
  position: relative;
  width: 100%;
}

.mobile-menu .tg-mega-menu-wrap.black-bg .mega-menu-content .title a {
  color: var(--tg-white);
}

.mobile-menu .tg-mega-menu-wrap.black-bg .mega-menu-content .title {
  color: var(--tg-white);
}

@media (max-width: 1800px) {
  .custom-container {
    max-width: 1570px;
  }
}

@media (max-width: 1199.98px) {
  .tg-mega-menu-wrap {
    width: 880px;
    padding: 40px 30px 10px;
  }

  .mega-menu-content .title {
    font-size: 16px;
  }

  .mega-menu-content .title a {
    font-size: 16px;
  }

  .navbar-wrap ul li a {
    padding: 40px 15px;
  }
}

@media (max-width: 1023.98px) {

  .header-action>ul li.header-btn {
    display: none;
  }

  .navbar-wrap {
    display: none !important;
  }
}

@media (max-width: 991.98px) {

  .custom-container {
    max-width: 720px;
  }

  .menu-area.transparent-header {
    padding: 20px 0;
  }

  .header-action {
    margin-right: 40px;
  }
}

@media (max-width: 767.98px) {
  .custom-container {
    max-width: 100%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .custom-container {
    max-width: 540px;
  }
}

@media only screen and (min-width: 0px) and (max-width: 640px) {
  .list-wrap-footer {
    margin-bottom: 20px;
  }

  .list-wrap-footer>li,
  .footer-contact>a {
    margin-bottom: 4px;
    list-style: none;
    font-size: 14px;
  }
}